// Imports
//=require vanilla-lazyload/dist/lazyload.min.js
//=require svg4everybody/dist/svg4everybody.min.js
//=require objectFitPolyfill/dist/objectFitPolyfill.min.js
//=require jquery/dist/jquery.min.js
//=require jquery-mask-plugin/dist/jquery.mask.min.js
//=require jquery-validation/dist/jquery.validate.min.js

if ($('html').attr('lang') == 'en-US') {
    var messages = {
        zipcode: {
            valid: '"Please enter a valid zip code"',
            required: 'Please enter your zip code'
        },
        address: {
            required: 'Please enter your address'
        },
        lastName: {
            required: 'Please enter your last name'
        },
        firstName: {
            required: 'Please enter your first name'
        },
        email: {
            required: 'Please enter your email'
        },
        phone: {
            required: 'Please enter your phone number',
            minlength: 'Please enter 10 digits',
            maxlength: 'Please enter 10 digits'
        },
    }
} else if ($('html').attr('lang') == 'es-US') {
    $.extend($.validator.messages, {
        required: 'Este campo es obligatorio.',
        remote: 'Por favor, rellena este campo.',
        email: 'Por favor, escribe una dirección de correo válida.',
        url: 'Por favor, escribe una URL válida.',
        date: 'Por favor, escribe una fecha válida.',
        dateISO: 'Por favor, escribe una fecha (ISO) válida.',
        number: 'Por favor, escribe un número válido.',
        digits: 'Por favor, escribe sólo dígitos.',
        creditcard: 'Por favor, escribe un número de tarjeta válido.',
        equalTo: 'Por favor, escribe el mismo valor de nuevo.',
        extension: 'Por favor, escribe un valor con una extensión aceptada.',
        maxlength: $.validator.format('Por favor, no escribas más de {0} caracteres.'),
        minlength: $.validator.format('Por favor, no escribas menos de {0} caracteres.'),
        rangelength: $.validator.format('Por favor, escribe un valor entre {0} y {1} caracteres.'),
        range: $.validator.format('Por favor, escribe un valor entre {0} y {1}.'),
        max: $.validator.format('Por favor, escribe un valor menor o igual a {0}.'),
        min: $.validator.format('Por favor, escribe un valor mayor o igual a {0}.'),
        nifES: 'Por favor, escribe un NIF válido.',
        nieES: 'Por favor, escribe un NIE válido.',
        cifES: 'Por favor, escribe un CIF válido.'
    });
    
    var messages = {
        zipcode: {
            valid: '"Por favor, ingrese un código postal válido"',
            required: 'Por favor, ingrese su código postal'
        },
    }
}

svg4everybody();
var lazyLoadInstance = new LazyLoad();

function setInputFilter(textbox, inputFilter) {
    ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
        textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty('oldValue')) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
        });
    });
}

/**
 * Focus on keydown
 */

let keyboardNavigation = false;

$(document).on('keydown', function (e) {
    if (e.key == 'Tab') {
        $('body').addClass('is-keyboard-pressed');
        keyboardNavigation = true;
    };
});

$(document).on('click mousedown', function (e) {
    var $target = $(e.target);
    if ($target.is(':radio, :checkbox, :button, a[href^="#"]')) {
        if (keyboardNavigation == false) {
            $('body').removeClass('is-keyboard-pressed');
            keyboardNavigation = false;
        }
    } else {
        $('body').removeClass('is-keyboard-pressed');
        keyboardNavigation = false;
    }
});
$('input[type=radio], input[type=checkbox], button[type="button"], a[href^="#"]').on('keydown', function (e) {
    $('body').addClass('is-keyboard-pressed');
    keyboardNavigation = true;
});


// DOM
var MyApp = {

    filters: function () {
        if ($('.only-numbers').length) {
            $('.only-numbers').each(function () {
                setInputFilter(this, function (value) {
                    return /^[0-9]*$/.test(value);
                });
            });
        }
        if ($('.only-letters').length) {
            $('.only-letters').each(function () {
                setInputFilter(this, function (value) {
                    return /^[a-zA-ZáéíóúÁÉÍÓÚ\'´ñÑäëïöüÄËÏÖÜ \-]*$/.test(value);
                });
            });
        }
        if ($('.only-alpha-numeric').length) {
            $('.only-alpha-numeric').each(function () {
                setInputFilter(this, function (value) {
                    return /^[0-9a-zA-Z]*$/.test(value);
                });
            });
        }
        if ($('.only-phone').length) {
            $('.only-phone').each(function () {
                setInputFilter(this, function (value) {
                    return /^[0-9\-]*$/.test(value);
                });
            });
            $('.only-phone').mask('000-000-0000');
        }
        if ($('.only-date').length) {
            $('.only-date').each(function () {
                setInputFilter(this, function (value) {
                    return /^[0-9\/]*$/.test(value);
                });
            });
        }
    },
    form: function () {
        $.validator.methods.email = function (value, element) {
            return this.optional(element) || /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
        }

        $('.form').each(function () {
            $(this).validate({
                errorClass: 'form__error',
                validClass: 'form__valid',
                submitHandler: function (form) {
                    $(form).addClass('form_loading');
                    form.submit();
                }
            });
        });

        if ($('#zipcode').length) {
            $('#zipcode').rules('add', {
                required: true,
                digits: true,
                minlength: 3,
                maxlength: 5,
                remote: {
                    url: 'https://www.confiejarvis.com/thor/javascript?',
                    data: {
                        zipcode: function () { return $('#zipcode').val(); }
                    },
                    dataFilter: function (data) {
                        if (data === "1") {
                            return '"true"';
                        } else {
                            return messages.zipcode.valid;
                        }
                    }
                },
                messages: {
                    required: messages.zipcode.required
                }
            });
        }
        if ($('#firstName').length) {
            $('#firstName').rules('add', {
                required: true,
                minlength: 2,
                messages: {
                    required: messages.firstName.required
                }
            });
        }
        if ($('#lastName').length) {
            $('#lastName').rules('add', {
                required: true,
                minlength: 2,
                messages: {
                    required: messages.lastName.required
                }
            });
        }
        if ($('#email').length) {
            $('#email').rules('add', {
                required: true,
                minlength: 3,
                maxlength: 120,
                messages: {
                    required: messages.email.required
                }
            });
        }
        if ($('#street').length) {
            $('#street').rules('add', {
                required: true,
                minlength: 3,
                maxlength: 120,
                messages: {
                    required: messages.address.required
                }
            });
        }
        if ($('#phone').length) {
            $('#phone').rules('add', {
                required: true,
                minlength: 12,
                maxlength: 12,
                messages: {
                    required: messages.phone.required,
                    minlength: messages.phone.minlength,
                    maxlength: messages.phone.maxlength
                }
            });
        }
    }
}

// On ready

$(function () {

    MyApp.filters();
    if ($('.form').length) {
        MyApp.form();
    }
});